import React from "react";
import { Grid } from "semantic-ui-react";
import Helmet from "react-helmet";
import {
  PageLayout,
  ImageCollection,
  HeroElement
} from "@fishrmn/fishrmn-components";
import emailCallback from "../utils/email.js";
import MessageBanner from "../components/MessageBanner/MessageBanner";

import Layout from "../components/Layout";

export default class extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <MessageBanner />
        <Layout>
          <Helmet>
            <title>Thai Amarin | Gallery</title>
            <meta name="description" content="" />
          </Helmet>
          <PageLayout
            hero={
              <HeroElement
                header={"Gallery"}
                tagline={""}
                images={[
                  "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03201.JPG"
                ]}
                showMap={false}
                ctas={[]}
                ctaColor={"white"}
                ctaInverted={true}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={true}
                containerAs={"solid"}
                height={"100vh"}
                gutter={false}
              />
            }
          >
            <Grid
              stackable
              className="component-section-container"
              verticalAlign={"middle"}
              textAlign={"center"}
            >
              <Grid.Column width={16}>
                <ImageCollection
                  as={"gallery"}
                  centered={true}
                  cutUnevenRows={true}
                  disable={"none"}
                  header={""}
                  images={[
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9665.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/FullSizeRender.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_6899.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/FullSizeRender_2.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03511.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/A7F60B35-DBF1-4130-807A-028ADFA4FB33_2.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9010_2.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9720.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03514.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/9722E722-9167-4CB6-9AF7-6C5FF803ADD0.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03201.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/4344BA2F-1072-450C-9B30-906E99C1BAA3.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9670.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03224.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03214.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_9676.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03303_2.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03293_2.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/IMG_8769_2.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03449_Fr543Pr.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03464_GSUqi0P.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03492_6WDpV9L.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03456_8duJxN1.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03450_gtefe8Z.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03446_AhuxP8C.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03431_0rOV3dM.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03441_1UBE0Jm.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03425.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03442_zWKdx6m.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03467_axPDAss.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03457_HzbuOK6.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03426_XTjtIo3.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03465.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03489.JPG",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03478.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03493.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03472.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03496.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03471.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03494.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03498.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DSC03454_KVViW4e.jpg",
                    "https://fisherman-static.s3.amazonaws.com/public/thaiamarin/DiningRoom.jpg"
                  ]}
                  numberOfRowDisplayItems={3}
                />
              </Grid.Column>
            </Grid>
          </PageLayout>
        </Layout>
      </React.Fragment>
    );
  }
}
